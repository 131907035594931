.header-transparent {
  .navbar-nav > .nav-item {
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1420px) {
  .header-transparent {
    .navbar-nav > .nav-item {
      & > .nav-link {
        margin: 0px 9px;
      }
    }
  }
}

@media screen and (max-width: 1370px) {
  .header-transparent {
    .navbar-nav > .nav-item {
      & > .nav-link {
        margin: 0px 9px;
      }
    }

    .gymfitness-header-menu {
      justify-content: start;
    }

    .header_btn_container {
      gap: 10px;

      i {
        margin: 5px;
      }
    }
  }
}

@media screen and (max-width: 1310px) {
  .header-transparent {
    .navbar-nav > .nav-item {
      & > .nav-link {
        font-size: 12px;
      }
    }

    .gymfitness-header-menu {
      justify-content: start;
    }

    .header_btn_container {
      gap: 10px;

      i {
        margin: 5px;
      }

      .header_btn {
        button {
          padding: 2px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1290px) {
  .header-transparent {
    padding: 0px !important;

    .navbar-nav > .nav-item {
      & > .nav-link {
        margin: 0px 5px;
        font-size: 12px;
      }
    }

    .header_btn_container {
      gap: 5px;

      i {
        margin: 5px;
      }

      .header_btn {
        button {
          padding: 0px 2px;
          gap: 0px;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .header-transparent {
    padding: 0px !important;

    .navbar-nav > .nav-item {
      & > .nav-link {
        margin: 0px 5px;
        font-size: 12px;
      }
    }

    .header_btn_container {
      gap: 5px;

      i {
        margin: 5px;
      }

      .header_btn {
        button {
          padding: 0px 2px;
          gap: 0px;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .header-transparent {
    .navbar-nav > .nav-item {
      & > .nav-link {
        font-size: 11px;
      }
    }

    .header_btn_container {
      width: 30%;
      justify-content: start;
    }
  }
}

@media screen and (max-width: 992px) {
  .header-transparent {
    padding: 10px 0px !important;
    .header_btn_container {
      display: none !important;
    }
  }
  .sticky-header {
    &.header-appear {
      &.reverse-scroll {
        .mobile-logo {
          display: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header-transparent {
    padding: 10px 0px !important;
    .header_btn_container {
      display: none !important;
    }
  }
}
